import { state } from ":mods";
import { getModuleDetails } from "../../apis";
import { createModuleInputs } from "../../forms/module";
import { Match, Switch, createEffect, createRenderEffect, onMount } from "solid-js";

export function ModuleForm({
  stageID,
  moduleID,
  buttonMsg,
  onDone,
}: {
  stageID: number | string;
  moduleID?: number | string;
  buttonMsg?: string;
  onDone?: (values: ReturnType<ReturnType<typeof createModuleInputs>["Actions"]["getValuesSnakecase"]>) => void;
}) {
  const $details = state.createAsync([getModuleDetails, moduleID], { manualFetch: { startSuccess: true } });
  const Form = createModuleInputs(stageID);
  $details.on((event, { value }) => {
    if (moduleID) {
      console.log("updating with module details");
      Form.Actions.update($details.value.data);
    }
  });
  createEffect(() => {
    console.log("from effect of module-form :: ", $details?.value?.data);
  });

  onMount(() => {
    if (moduleID) {
      $details.refetch();
    }
  });
  function onSubmit() {
    const values = Form.Actions.getValuesSnakecase();
    // console.log("on submit ::: ", values);
    if (onDone) {
      onDone(values);
    }
  }
  return (
    <Switch>
      <Match when={$details.state.loading}>Loading Module Details...</Match>
      <Match when={$details.state.failed}>An error occured while loading module details {}...</Match>
      <Match when={$details.state.success}>
        <div class="w-full h-full flex flex-col">
          <Form.ID.Label title="ID" />
          <Form.ID.Input class="form-input" type="text" />
          <Form.Stage.Label title="Description" />
          <Form.Stage.Input class="form-input" type="text" />
          <Form.Name.Label title="Name" />
          <Form.Name.Input class="form-input" type="text" />
          <Form.Description.Label title="Description" />
          <Form.Description.Input class="form-input" type="text" />
          <Form.TotalMinutesVideo.Label title="TotalMinutesVideo" />
          <Form.TotalMinutesVideo.Input class="form-input" type="text" />
          <Form.TotalMinutesReading.Label title="TotalMinutesReading" />
          <Form.TotalMinutesReading.Input class="form-input" type="text" />
          <Form.TotalMinutesQuestions.Label title="TotalMinutesQuestions" />
          <Form.TotalMinutesQuestions.Input class="form-input" type="text" />
          <Form.TotalMinutesTasks.Label title="TotalMinutesTasks" />
          <Form.TotalMinutesTasks.Input class="form-input" type="text" />

          <Form.Actions.Button
            class="form-btn !w-fit !min-h-50px px-2 mt-2"
            statusValid={buttonMsg ?? "Create Module"}
            onSubmit={onSubmit}
            onlyStatus={["valid"]}
          />
        </div>
      </Match>
    </Switch>
  );
}
